<template>
      <div class="processWorksheetEdit">
        <v-breadcrumbs>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
            >Home</v-breadcrumbs-item
          >
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'processWorksheet', params: {} }">
          Process Worksheet
          </v-breadcrumbs-item>
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :to="{ name: 'processWorksheetEdit', params:{id: id} }">
            Edit Process Worksheet
          </v-breadcrumbs-item>
        </v-breadcrumbs>

        <v-card>
          <v-card-title>Edit Process Worksheet</v-card-title>
          <v-card-text>
            <processWorksheet-form :id="id"></processWorksheet-form>
          </v-card-text>
        </v-card>
      </div>
    </template>

    <script>
    import ProcessWorksheetForm from './ProcessWorksheetForm.vue';

    export default {
      name: 'processWorksheet',
      data () {
        return {
          id: null
        }
      },
      components: {
        ProcessWorksheetForm
      },
      created () {
          this.id = this.$route.params.id;
          this.$emit("showParent", false);
      }
    }
    </script>
    