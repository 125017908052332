<template>
  <div :style="cssVars">
    <v-breadcrumbs class="d-print-none">
      <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
        >Home</v-breadcrumbs-item
      >
      <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
      <v-breadcrumbs-item
        :exact="true"
        :to="{ name: 'processWorksheet', params: {} }"
      >
        Process Worksheet
      </v-breadcrumbs-item>
      <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
      <v-breadcrumbs-item :to="{ name: 'processWorksheetView', params: {} }">
        Worksheet Print QR Code
      </v-breadcrumbs-item>
    </v-breadcrumbs>

    <div class="d-print-none">
      <page-params
        pageName="worksheetPrintQR"
        vuexName="qrPrintParameters"
        :parameters="settings"
      />
      <v-divider />
      <v-subheader>Print Settings</v-subheader>
      <div>
        <div class="d-flex">
          <v-text-field label="Brand Name" class="mx-2"
            outlined
            dense v-model="parameters.brandName"/>
          <v-btn class="ml-2" @click="addStickerField()">Add Field</v-btn>
        </div>
      </div>
      <div v-for="(field, index) in parameters.optionalFields" :key="index">
        <div class="d-flex">
          <v-text-field label="Field Label" class="mx-2"
              outlined
              dense v-model="field.label"/>
          <v-text-field label="Field Value" class="mx-2"
              outlined
              dense v-model="field.value"/>
          <v-btn @click="deleteField(index)" icon><v-icon>mdi-delete-outline</v-icon></v-btn>
        </div>
      </div>
      <div class="d-flex">
        <v-text-field
          class="mx-2"
          suffix="px"
          outlined
          dense
          label="Left"
          v-model="parameters.left"
        ></v-text-field>
        <v-text-field
          class="mx-2"
          suffix="px"
          outlined
          dense
          label="Top"
          v-model="parameters.top"
        ></v-text-field>
        <v-text-field
          class="mx-2"
          suffix="px"
          outlined
          dense
          label="Right"
          v-model="parameters.right"
        ></v-text-field>
        <v-text-field
          class="mx-2"
          suffix="px"
          outlined
          dense
          label="Bottom"
          v-model="parameters.bottom"
        ></v-text-field>
      </div>
      <div class="d-flex">
        <v-text-field
          class="mx-2"
          suffix="pt"
          outlined
          dense
          label="Font Size"
          v-model="parameters.fontSize"
        ></v-text-field>
        <v-text-field
          class="mx-2"
          suffix="pt"
          outlined
          dense
          label="Header Font Size"
          v-model="parameters.headerFontSize"
        ></v-text-field>
        <v-text-field
          class="mx-2"
          suffix="pt"
          outlined
          dense
          label="Footer Font Size"
          v-model="parameters.footerFontSize"
        ></v-text-field>
        <v-text-field
          class="mx-2"
          suffix="pt"
          outlined
          dense
          label="QR Code Size"
          v-model="parameters.qrSize"
        ></v-text-field>
        <v-text-field
          class="mx-2"
          suffix="cols"
          outlined
          dense
          label="Number of Columns"
          v-model="parameters.cols"
        ></v-text-field>
      </div>
      <div class="d-flex justify-center">
        <v-text-field class="mx-2 flex-grow-1" v-model="filterTerm" outlined dense label="Filter entries" hint="Example: 1, 2, 5 - 10" />
        <v-text-field class="mx-2 flex-grow-0" v-model.number="emptyPadding" outlined dense label="Empty Padding" />
        
        <v-btn class="mx-2" @click="print()">Print</v-btn>
      </div>
      <v-divider class="mb-5"/>
    </div>
    <v-row class="sticker-container" align-content="center">
      <v-col
        class="sticker"
        cols="12"
        sm="6"
        :md="12 / parameters.cols"
        v-for="(entry, index) in filteredEntries"
        :key="entry.id"
        :style="{
          paddingTop: parameters.top + 'px',
          paddingRight: parameters.right + 'px',
          paddingBottom: parameters.bottom + 'px',
          paddingLeft: parameters.left + 'px',
          fontSize: parameters.fontSize + 'pt',
        }"
      >
        <div v-if="entry.empty">
          <div :style="`height: ${cellHeight}px`"></div>
        </div>
        <div v-if="!entry.empty">
          <div :class="companyName.startsWith('Bergsoe') ? 'bg-img':'panya-bg-img'"></div>
          <div ref="cellContent" class="cell-content">
            <div class="d-flex">
              <div class="my-auto">
                <qriously :value="getQrCodeUrl(entry)" :size="parameters.qrSize" />
              </div>
              <!-- <qr-code :value="getQrCodeUrl(entry)" :size="parameters.qrSize" /> -->
              <div class="ml-3">
                <table class="qr-info">
                  <tbody>
                  <tr>
                    <td colspan="2" :style="`font-size: ${parameters.headerFontSize}pt`" class="font-weight-bold">{{ companyName }}</td>
                  </tr>
                  <tr v-if="parameters.brandName">
                    <td class="font-weight-light text-caption">Brand</td>
                    <td>{{ parameters.brandName }}</td>
                  </tr>
                  <tr v-if="processWorksheet.productName">
                    <td class="font-weight-light text-caption">Commodity</td>
                    <td>{{ processWorksheet.productName }}</td>
                  </tr>
                  <tr v-if="processWorksheet.documentNumber">
                    <td class="font-weight-light text-caption">Lot No</td>
                    <td>{{ processWorksheet.documentNumber }}-{{ entryIndex(entry) }}</td>
                  </tr>
                  <tr v-if="entry.netWeight">
                    <td class="font-weight-light text-caption">Net Weight</td>
                    <td>
                      {{ entry.netWeight + entry.netAdjustmentWeight | formatNumber }} {{ productInfo ? productInfo.measureWeight:'' }}
                    </td>
                  </tr>
                  <tr v-for="(field, index) in parameters.optionalFields" :key="index">
                    <td class="font-weight-light text-caption">{{field.label}}</td>
                    <td>{{ field.value }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-light text-caption">Date</td>
                    <td>{{ processWorksheet.processTime | formatDateYear }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-light text-caption">Origin</td>
                    <td>Thailand</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              class="ma-0 pa-0 font-weight-light"
              :style="`font-size: ${parameters.footerFontSize}pt;`"
              v-if="companyAddress"
            >
              {{ companyAddress }} Tel: {{ companyPhone }}
              <div class="text-caption font-weight-light font-size-small d-print-none" style="float: right; position: relative;">{{entryIndex(entry)}}</div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {encrypt} from "@/services/CryptoService.js";
import { getProcessWorksheet } from "@/services/processWorksheet";
import { mapActions, mapGetters } from "vuex";
import { parseDomain } from "parse-domain";
const PageParams = () => import("@/components/common/PageParams.vue");
// const QrCode = () => import("@/components/common/QrCode.vue");

export default {
  data() {
    return {
      companyName: process.env.VUE_APP_COMPANY_NAME,
      companyAddress: process.env.VUE_APP_COMPANY_ADDRESS,
      companyPhone: process.env.VUE_APP_COMPANY_PHONE,
      id: "",
      processWorksheet: {},
      loaded: false,
      relations: {},
      productInfo: null,
      domainUrl: null,
      filterTerm: null,
      emptyPadding: 0,
      cellHeight: 100,
    };
  },
  components: {
    PageParams, 
    // QrCode
  },
  computed: {
    ...mapGetters("worksheetTypeDefinition", ["worksheetTypeDefinitions"]),
    ...mapGetters("qrPrintParameters", ["parameters"]),

    cssVars() {
      return {
        '--sticker-margin-top': this.parameters.top,
        '--sticker-margin-right': this.parameters.right,
        '--sticker-margin-bottom': this.parameters.bottom,
        '--sticker-margin-left': this.parameters.left,
      }
    },
    settings() {
      return this.parameters;
    },
    netAdjustPercentage() {
      const netAjustWeight = this.processWorksheet.netAdjustmentWeight
        ? this.processWorksheet.netAdjustmentWeight
        : 0;
      const totalWeight = this.totalWeight(this.processWorksheet.entries);
      return totalWeight ? (netAjustWeight / Math.abs(totalWeight)) * 100 : 0;
    },
    worksheetTypeFields() {
      if (
        this.worksheetTypeDefinitions &&
        this.worksheetTypeDefinitions.length > 0 &&
        this.processWorksheet.worksheetTypeId
      ) {
        return this.worksheetTypeDefinitions.find(
          (t) => t.id == this.processWorksheet.worksheetTypeId
        );
      } else {
        return {};
      }
    },
    filteredEntries(){
      let entries = [];
      if(this.processWorksheet && this.processWorksheet.entries){
        if(this.filterTerm){
          const matchIndexes = this.parseFilterTerms(this.filterTerm);
          entries = this.processWorksheet.entries.filter( (e, index) => {
            return matchIndexes.includes(index+1);
          })
        }
        else{ 
          entries = this.processWorksheet.entries; 
        }
      }

      if(this.emptyPadding > 0 && entries.length > 0){
        let emptyCells = []
        for(var i=0; i<this.emptyPadding; i++){
          emptyCells.push({empty: true});
        }
        entries = emptyCells.concat(entries);
      }
      
      return entries;
    }
  },
  methods: {
    ...mapActions("navigation", ["hideDrawer"]),
    ...mapActions("processes", ["loadProcessTypes"]),
    ...mapActions("worksheetTypeDefinition", ["fetchWorksheetTypeDefinitions"]),
    ...mapActions("qrPrintParameters", ["updateParameters"]),
    ...mapActions("categoryStore", ["fetchCategory"]),

    goBack() {
      this.$router.go(-1);
    },
    edit(id) {
      this.$router.push({ name: "processWorksheetEdit", params: { id: id } });
    },
    async print() {
      await this.hideDrawer();
      window.print();
    },
    setInstance() {
      this.setDependencies().then(() => {
        getProcessWorksheet(this.id)
          .then((response) => {
            this.processWorksheet = response.data;

            this.loadProductInfo();

            this.loaded = true;
          })
          .catch(() => {
            this.addErrorMessage("Error loading processWorksheet");
            this.loaded = true;
          });
      });
    },
    async setDependencies() {
      return await this.loadProcessTypes();
    },
    getRelationText(id, relationProperty, relationAttribute) {
      const relationList = this.relations[relationProperty];
      if (relationList) {
        const relationObj = relationList.find((r) => r.id == id);
        return relationObj[relationAttribute];
      } else {
        return "";
      }
    },
    totalWeight(entries) {
      if (entries && entries.length > 0) {
        return entries.reduce((sum, a) => {
          return (sum += a.netWeight);
        }, 0);
      } else {
        return 0;
      }
    },
    getQrCodeUrl(entry) {

      const qrData = {
        owner: this.companyName,
        type: 'DATA',
        businessType: 'WorksheetEntry',
        worksheetId: this.processWorksheet.id,
        entryId: entry.id,
        timestamp: Date.now(),
      }
      // if(this.processWorksheet.documentNumber){
      //   qrData.documentNumber = this.processWorksheet.documentNumber;
      // }
      // if(this.processWorksheet.productId){
      //   qrData.productId = this.processWorksheet.productId;
      //   qrData.productName = this.processWorksheet.productName;
      // }
      // if(this.processWorksheet.supplierId){
      //   qrData.supplierId = this.processWorksheet.supplierId;
      //   qrData.supplierName = this.processWorksheet.supplierName;
      // }
      // if(this.processWorksheet.customerId){
      //   qrData.customerId = this.processWorksheet.customerId;
      //   qrData.customerName = this.processWorksheet.customerName;
      // }

      const qrDataStringify = JSON.stringify(qrData);

      // Encrypt
      var ciphertext = encrypt(qrDataStringify);
      return ciphertext;


      // return `${this.domainUrl}/processWorksheet/view/${this.processWorksheet.id}?entry=${entry.id}`;
    },
    async loadProductInfo(){
      if(this.processWorksheet.productId){
        return await this.fetchCategory(this.processWorksheet.productId)
          .then((resp) => {
            this.productInfo = resp;

            return this.productInfo;
          })
      }
      else{ return null;}
    },
    entryIndex(entry){
      if(this.processWorksheet && this.processWorksheet.entries){
        const index = this.processWorksheet.entries.findIndex(e => e.id == entry.id);
        if(index >= 0){
          return index + 1;
        }
        else{ return 0 }
      }
    },
    parseFilterTerms(terms){
      const entryIndexes = new Set();
      if(terms){
        const list = terms.split(',');
        list.forEach(l => {
          //check if range
          if(l.includes('-')){
            const range = l.split('-');
            if(range.length == 2){
              const start = parseInt(range[0].trim());
              const end = parseInt(range[1].trim());
              let cursor = start;
              while (cursor <= end) {
                entryIndexes.add(cursor);
                cursor++;
              }
            }
          }
          else{ 
            entryIndexes.add(parseInt(l.trim()));
          }
        })
      }
      return [...entryIndexes];
    },
    addStickerField(){
      let fields = this.parameters.optionalFields;
      if(!fields){
        fields = []
      }
      fields.push({label: `Field ${fields.length+1} Name`, value: ''});
      this.$set(this.parameters, 'optionalFields', fields);
    },
    deleteField(index){
      let fields = this.parameters.optionalFields;
      if(fields){
        fields.splice(index, 1);
        this.$set(this.parameters, 'optionalFields', fields);
      }
    }
  },
  async created() {
    this.id = this.$route.params.id;
    await this.fetchWorksheetTypeDefinitions();
    this.setInstance();
    this.$emit("showParent", false);

    const parseResult = parseDomain(window.location.hostname);
    const { domain, topLevelDomains } = parseResult;

    if (domain) {
      const topLevel = topLevelDomains.join(".");
      this.domainUrl = `https://factory.${domain}.${topLevel}/#`;
    } else {
      this.domainUrl = `http://localhost:${process.env.VUE_APP_PORT}/#`;
    }
  },
  updated(){
     const cells = this.$refs.cellContent;
    if(cells && cells.length > 0){
      this.cellHeight = cells[0].clientHeight;
    }
  }
};
</script>
<style scoped>
table.qr-info > tbody > tr > td:nth-child(1){
  padding-right: 5px;
}

div.sticker {
  border: 1px dashed black;
}

div.bg-img{
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: .15;
  background-size: 200px;
  background-image: url('~@/assets/logo.png');
  background-position: center;
  margin-top: calc(-1px * var(--sticker-margin-top));
  margin-left: calc(-1px * var(--sticker-margin-left));
  margin-right: calc(-1px * var(--sticker-margin-right));
  margin-bottom: calc(-1px * var(--sticker-margin-bottom));
}

div.panya-bg-img{
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: .35;
  background-size: 200px;
  background-image: url('~@/assets/panya-logo.png');
  background-position: center;
  margin-top: calc(-1px * var(--sticker-margin-top));
  margin-left: calc(-1px * var(--sticker-margin-left));
  margin-right: calc(-1px * var(--sticker-margin-right));
  margin-bottom: calc(-1px * var(--sticker-margin-bottom));
}

@media print {

  @page {
    margin: 6mm 4mm 5mm 5mm;
    orientation: portrait;
  }

  div.sticker-container {
    display: table;
  }

  div.sticker {
    position: relative;
    break-inside: avoid;
    page-break-after: always;
    page-break-inside: avoid;
    display: inline-block;
    border: 0px;

    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari, Edge */
    color-adjust: exact !important;                 /*Firefox*/
  }
}
</style>
