<template>
      <div class="processWorksheetCreate">
        <v-breadcrumbs>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
            >Home</v-breadcrumbs-item
          >
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'processWorksheet', params: {} }">
          Process Worksheet
          </v-breadcrumbs-item>
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :to="{ name: 'processWorksheetCreate', params: {} }">
            Create Process Worksheet
          </v-breadcrumbs-item>
        </v-breadcrumbs>

        <v-card>
          <v-card-title>Create Process Worksheet</v-card-title>
          <v-card-text>
            <processWorksheet-form></processWorksheet-form>
          </v-card-text>
        </v-card>
      </div>
    </template>
    <script>
    import ProcessWorksheetForm from './ProcessWorksheetForm.vue';

    export default {
      name: 'processWorksheet',
      components: {
        ProcessWorksheetForm
      },
      created() {
        this.$emit("showParent", false);
      }
    }
    </script>
    