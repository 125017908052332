import CryptoJS from "crypto-js";

const key = process.env.VUE_APP_CRYPTR_KEY;
const encrypt = (string) => {
  var ciphertext = CryptoJS.AES.encrypt(string, key).toString();
  return ciphertext;
};

const decrypt = (ciphertext) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, key);
  try {
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  } catch (error) {
    console.error("error decrypting data, returing original ciphertext");
    return ciphertext;
  }
};

export { encrypt, decrypt };
